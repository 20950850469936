var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding-top":"70px","justify-content":"center"},attrs:{"fluid":""}},[_c('alert',{ref:"alertComponent"}),_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"tile":"","outlined":"","color":"success"},on:{"click":_vm.loadQueue}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cached")]),_vm._v(" Refresh ")],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"cyan"}}):_vm._e()],1),_c('br'),_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pipelines,"search":_vm.search,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.video",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.video.playlist && item.video.playlist.type === 'SERIES' ? item.video.playlist.titleVO + ' : ' + item.video.title : item.video.title)+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-4",attrs:{"color":_vm.getColorForState(item.state)}},[_vm._v(_vm._s(item.state))])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.durationString(item))+" ")]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Pipeline', params: { id: item.id }}}},[_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","color":"#fbc02d"}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }